.main-content-wrapper {
    /*background: url('../../assets/images/bg-1.png');
    background-repeat: no-repeat;
    background-size: 100%;
    flex: 1;*/
    overflow-x: hidden;
    min-height: calc(100% - 78px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

@media only screen and (max-width: 950px) {
    .main-content-wrapper {
        min-height: calc(100% - 67px);
    }
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 40px 20px 50px 20px;
}

.tag {
    color: #000;
    text-align: center;
    font-family: 'DM Sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 99.327%;
    margin-bottom: 10px;
    position: relative;
}

.tag .circle-1 {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 130px;
    left: 80px;
    background-color: var(--pinkDark);
    border-radius: 50%;
}

.tag .circle-2 {
    width: 128px;
    height: 128px;
    position: absolute;
    top: 80px;
    right: 0%;
    background-color: #FFDC60;
    border-radius: 50%;
}

.main h1 {
    color: #000;
    text-align: center;
    font-family: 'DM Sans';
    font-size: 80px;
    font-weight: 600;
    line-height: 110%;
    margin: 0px 0px 60px 0px;
    z-index: 100;
}

.qa_card {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    margin-bottom: 15px;
}

.qa_card .question-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background: var(--merquryDarkBlue);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 15px 20px;
    cursor: pointer;
}

.qa_card .question-wrapper>span {
    flex: 1
}

.qa_card .question-wrapper>.icon {
    flex-shrink: 0;
    margin-top: 3px;
}

.qa_card .answer-wrapper {
    padding: 10px 20px;
    border: 1px solid var(--merquryDarkBlue);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: none;
    flex-direction: column;
}

.qa_card .answer-wrapper.open {
    display: flex;
}

.qa_card .answer-wrapper p {
    margin: 0px 0px 0px 0px;
    font-size: 16px;
}

.qa_card .answer-wrapper p:not(:last-child) {
    margin: 0px 0px 10px 0px;
}

.qa_card .answer-wrapper table {
    border: 1px solid var(--merquryDarkBlue)
}

.qa_card .answer-wrapper td {
    border: 1px solid var(--merquryDarkBlue)
}

.qa_card .answer-wrapper ul {
    margin: 0px 0px 10px 0px;
}

.qa_card .answer-wrapper a {
    color: var(--merquryDarkPurple);
    text-decoration: underline;
    font-weight: 700;
}

@media only screen and (max-width: 740px) {

    .main h1 {
        color: #000;
        text-align: center;
        font-family: 'DM Sans';
        font-size: 70px;
    }

}

@media only screen and (max-width: 660px) {
    .main-content-wrapper {
        /*background: url('../../assets/images/bg-2.png');
        background-repeat: no-repeat;
        background-size: 100%;*/
    }

    .main {
        padding: 30px 20px 20px 20px;
    }

    .tag {
        display: none;
    }

    .main h1 {
        font-size: 40px;
        margin: 0px 0px 30px 0px;
    }

    .qa_card .question-wrapper {
        font-size: 14px;
        padding: 10px 15px;
    }

    .qa_card .answer-wrapper p {
        font-size: 14px;
    }

    .qa_card .answer-wrapper table,
    .qa_card .answer-wrapper ul {
        font-size: 14px;
    }

    .qa_card .answer-wrapper {
        padding: 10px 15px;
    }

}