h2 {
    color: #000;
    text-align: center;
    font-family: 'DM Sans';
    font-size: 44px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -2.88px;
    margin: 0px 0px 32px 0px;
}

.legal-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 550px) {

    h2 {
        font-size: 34px;
        margin: 0px 0px 22px 0px;
    }
}

.legal-wrapper .header-style {
    font-size: 22px;
    color: var(--merquryDarkBlue);
    margin-top: 20px;
    font-weight: 700;
    width: 100%;
}

.legal-wrapper .header-style:first-child {
    margin-top:0px;
}

.legal-wrapper .subheader-style {
    font-size: 18px;
    color: var(--blueDark);
    margin-top: 20px;
    font-weight: 700
}

.legal-wrapper p {
    font-size: 16px;
    margin: 20px 0px 0px 0px;
}

@media only screen and (max-width: 550px) {

    .legal-wrapper .header-style {
        font-size: 20px;
    }

    .legal-wrapper .subheader-style {
        font-size: 16px;
    }

    .legal-wrapper p {
        font-size: 14px;
    }

}