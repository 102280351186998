.main-content-wrapper {
    overflow-x: hidden;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    padding: 30px 20px 20px 20px;
}

.main .top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main .top-section h2 {
    font-size: 32px;
    font-weight: 600;
    color: #000;
    margin: 0px 0px 10px 0px;
    text-align: center;
}

.main .top-section span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin: 0px 0px 10px 0px;
    text-align: center;
}

.main .bottom-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 35px 0px 0px 0px
}

.main .bottom-section img {
    height: 35px;
    width: auto;
    margin: 4px 10px;
}

.main .bottom-section span {
    font-size: 14px;
    font-weight: 500;
    margin: 4px 5px;
}