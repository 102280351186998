.main-content-wrapper {
    overflow-x: hidden;
    padding: 0px 20px;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 100px 100px;
    border-radius: 40px;
    background: linear-gradient(90deg, #ECF2F6 0%, rgba(236, 242, 246, 0.00) 70.31%);
    position: relative;
    margin: 80px auto;
}

.main>h2 {
    color: #000;
    font-family: 'DM Sans';
    font-size: 70px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -2px;
    margin: 0px 0px 24px 0px;
    max-width: 480px;
    text-align: left;
}

.main>span {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    opacity: 0.7;
    margin-bottom: 0px;
    max-width: 480px;
}

.app-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.app-links-button {
    font-size: 20px;
    padding: 15px 40px;
}

.app-links-button:nth-child(2) {
    color: var(--merquryDarkBlue);
    background: #fff0;
    border: 1px solid var(--merquryDarkBlue);
    margin-left: 10px;
}

.cta-image {
    position: absolute;
    right: 0px;
    max-width: 380px;
}

.app-download-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 60px;
    z-index: 100;
}

.app-download-links>a {
    cursor: pointer;
    flex: 1;
    display: flex;
}

.app-download-links>a:hover {
    opacity: 0.8;
}

.app-download-links>a:first-child {
    margin-right: 30px;
}

.app-download-links>a>img {
    max-width: 230px;
    height: auto;
    width: 100%;
}

@media only screen and (max-width: 710px) {

    .app-download-links {
        margin-top: 37px;
        width: 100%;
        justify-content: center;
    }

    .app-download-links a img {
        margin: 0px auto
    }

    .app-download-links>a:first-child {
        margin-right: 30px;
    }
}


@media only screen and (max-width: 1020px) {

    .main>h2 {
        max-width: 480px;
    }

    .main>span {
        max-width: 400px;
    }

    .cta-image {
        position: absolute;
        max-width: 35%;
    }
}

@media only screen and (max-width: 800px) {

    .main {
        padding: 50px 50px;
    }

    .main>h2 {
        font-size: 55px;
        max-width: 400px;
    }

    .main>span {
        max-width: 300px;
    }

    .cta-image {
        top: 20px;
    }

    
    .app-links-button {
        font-size: 18px;
        padding: 20px 20px;
        width: 100%;
        max-width: 250px;
    }
}


@media only screen and (max-width: 620px) {

    .main {
        align-items: center;
        margin: 20px auto;
        background: #edf3f6;
    }

    .cta-image {
        position: initial;
        width: 100%;
        max-width: 300px;
        align-self: flex-end;
        margin-bottom: 20px;
    }

    .main>h2 {
        font-size: 45px;
        max-width: 100%;
        text-align: center;
    }

    .main>span {
        max-width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .app-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .app-links-button {
        font-size: 18px;
        padding: 20px 20px;
        width: 100%;
        max-width: 250px;
    }

    .app-links-button:nth-child(2) {
        margin: 10px 0px 0px 0px;
    }

}