.main-content-wrapper {
    background-size: 100%;
    flex: 1;
    overflow-x: hidden;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 20px 30px 20px;
}

.main>.top {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0px 0px 100px 0px;
}

.main>.top>.left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1
}

.main>.top>.left .tag {
    color: #C75C;
    font-size: 20px;
    font-weight: 700;
    margin: 0px 0px 20px 0px;
}

.main>.top>.left .title {
    color: #000;
    font-size: 70px;
    font-weight: 500;
    margin: 0px 0px 40px 0px;
    text-align: left;
}

.main>.top>.left .desc {
    color: #999FAE;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
    max-width: 500px;
}

.main>.top>.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-basis: 250px;
    flex-shrink: 0;
}

.main>.top>.right img {
    width: 100%;
}

.main>.bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 100px;
    padding: 50px 10px 50px 10px;
}

.main>.bottom>div:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
}

.main>.bottom>div:nth-child(2) {
    grid-area: 1 / 2 / 2 /3;
}

.main>.bottom>div:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
}

.main>.bottom>div:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
}

.main>.bottom .grid-item {
    display: flex;
    flex-direction: row;
}

.grid-item .left {
    width: 100px;
    margin-right: 20px;
}

.grid-item .left img {
    width: 100%;
    height: auto
}

.grid-item .right {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.grid-item .right .title {
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
}

.grid-item .right .dec {
    color: #999FAE;
    font-weight: 400;
    font-size: 16px;
}

@media only screen and (max-width: 950px) {

    .main {
        max-width: 600px;
        padding: 70px 20px 30px 20px;
    }

    .main>.top {
        margin: 0px 0px 70px 0px;
    }

    .main>.top>.left .tag {
        font-size: 16px;
    }

    .main>.top>.left .title {
        font-size: 35px;
        margin: 0px 0px 20px 0px;
    }

    .main>.top>.left .desc {
        font-size: 16px;
    }

    .main>.top>.right {
        flex-basis: 10%;
    }

    .main>.top>.right img {
        width: 100px
    }

    .main>.bottom {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, auto);
        grid-row-gap: 50px;
        padding: 0px 10px 50px 10px;
    }

    .main>.bottom>div:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
    }

    .main>.bottom>div:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
    }

    .main>.bottom>div:nth-child(3) {
        grid-area: 3 / 1 / 4 / 2;
    }

    .main>.bottom>div:nth-child(4) {
        grid-area: 4 / 1 / 5 / 2;
    }

    .grid-item .left {
        width: 60px;
        margin-right: 20px;
    }

    .grid-item .right .title {
        font-size: 20px;
        margin-bottom: 10px;
    }
    
    .grid-item .right .dec {
        font-size: 15px;
    }
    
}