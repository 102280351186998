.main-content-wrapper {
    position: sticky;
    margin: 0px auto;
    background-color: #fff;
    z-index: 200;
    width: 100%;
    top: 0px;
}

.main {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 20px 20px 20px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.menu-icon {
    display: none;
}

.main>img {
    width: 210px;
    height: auto;
    margin-right: 52px;
    margin-bottom: 8px;
    cursor:pointer;
}

.action-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.menu>div {
    flex-basis: 20%;
    color: var(--lightGray);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.64px;
    margin: 0px 16px;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
}

.menu>div>.highight-border {
    width: 40px;
    height: 2px;
    border-radius: 4px;
    background: #fff0;
    margin: 8px auto 0px auto;
}

.menu>div.active {
    color: var(--merquryDarkBlue);
}

.menu>div.active>.highight-border {
    background: var(--orangeDark);
}

.menu>div:hover {
    color: var(--merquryDarkBlue);
}

.menu .submenu-container {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px 20px;
    border-radius: 10px;
    left:-50%;
}

.menu>div:hover .submenu-container {
    display: flex;
}

.menu .submenu-container>span {
    color: var(--lightGray);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.64px;
    margin: 10px 0px;
    white-space: nowrap;
    cursor: pointer;
    padding-bottom: 5px;
}

.menu .submenu-container>span:hover {
    color: var(--merquryDarkBlue);
}

.menu .submenu-container>span.active {
    color: var(--merquryDarkBlue);
    border-bottom: 1px solid var(--orangeDark);
}

.main .login-button {
    margin-left: auto;
    padding: 10px 30px;
    color: var(--merquryDarkBlue);
    border: 1px solid var(--merquryDarkBlue);
    background-color: #fff0;
}

.main .register-button {
    margin-left: 20px;
    padding: 10px 30px;
}

@media only screen and (max-width: 950px) {
    .main {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 20px 20px 20px;
    }

    .menu-icon {
        display: block;
        position: absolute;
        left: 20px;
        font-size: 30px;
        color: var(--merquryDarkBlue);
        cursor: pointer;
    }

    .main>img {
        margin: 0px;
        width: 120px;
    }

    .action-section {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex: 0;
        display: none;
        position: absolute;
        margin-top: 40px;
        width: 100%;
        background: #fff;
        z-index: 10;
    }

    .action-section.toggled {
        display: flex;
    }

    .menu {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0px 0px 0px 0px;
        width: 100%;
        padding: 0px 25px;
        border-bottom: 1px solid #ddd;
    }

    .menu>div {
        flex-basis: 100%;
        margin: 10px 0px;
    }

    .menu>div>.highight-border {
        width: 100%;
    }

    .menu>div.active.children-active>.highight-border {
        background: #ddd0;
    }

    .menu .submenu-container {
        display: flex;
        position: relative;
        box-shadow: none;
        padding: 10px 10px 0px 10px;
        border-radius: 10px;
        left:0%;
    }
    
    .menu .submenu-container>span {
        color: var(--lightGray);
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.64px;
        margin: 15px 0px;
        white-space: nowrap;
        cursor: pointer;
    }

    .main .login-button {
        display: none;
    }

    .main .register-button {
        display: none;
    }

}