.main-content-wrapper {
    overflow-x: hidden;
    padding: 70px 20px;
}

.main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px 100px;
    border-radius: 40px;
    background: #C5BCE1;
    position: relative;
    margin: 80px auto;
}

img.three-phones {
    flex-basis: 50%;
    width: auto;
    height: 140%;
    position: absolute;
    left: 20px;
}

.text-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.text-section .title {
    font-size: 50px;
    color: var(--merquryDarkBlue);
    font-weight: 700;
    text-align: right;
    margin: 0px 0px 20px 0px
}

.text-section .desc {
    font-size: 16px;
    color: #000;
    text-align: right;
    text-wrap: balance;
    margin: 0px 0px 30px 0px
}

.app-download-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    z-index: 100;
}

.app-download-links>a {
    cursor: pointer;
    flex: 1;
    display: flex;
}

.app-download-links>a:hover {
    opacity: 0.8;
}

.app-download-links>a:first-child {
    margin-right: 30px;
}

.app-download-links>a>img {
    max-width: 230px;
    height: auto;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .text-section .title {
        font-size: 40px;
        margin: 0px 0px 20px 0px
    }

    .text-section .desc {
        font-size: 14px;
        margin: 0px 0px 30px 0px
    }

    .app-download-links>a>img {
        max-width: 170px;
    }
}

@media only screen and (max-width: 880px) {
    img.three-phones {
        max-width: 200px;
        height: auto;
    }    
}

@media only screen and (max-width: 730px) {
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 30px;
        border-radius: 30px;
        margin: 80px auto;
    }

    img.three-phones {
        flex-basis: 100%;
        position: static;
        margin-top: -80px;
        max-width: 200px;
        max-height: 200px;
    }

    .text-section {
        align-items: flex-start;
        flex: 1;
    }

    .text-section .title {
        text-align: left;
        font-size: 30px;
    }

    .text-section .desc {
        text-align: left;
        text-wrap: wrap;
    }

    .button {
        flex-basis: 40%;
        padding: 20px 40px;
    }
}

.button:first-child {
    margin: 0px 20px 0px 0px;
}