.main-content-wrapper {
    overflow-x: hidden;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    padding: 50px 20px 20px 20px;
}

.main .top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main .top-section h2 {
    font-size: 32px;
    font-weight: 600;
    color: #000;
    margin: 0px 0px 10px 0px;
    text-align: center;
}

.main .top-section span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin: 0px 0px 10px 0px;
    text-align: center;
}

.main .bottom-section {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
}

.main .bottom-section .step-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom:40px
}

.main .bottom-section .step-item:nth-child(2n+0) {
    flex-direction: row-reverse;
}

.main .bottom-section .step-item .text-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    flex-basis: 53%;
}

.main .bottom-section .step-item:nth-child(2n+0) .text-section {
    align-items: flex-start;
}

.main .bottom-section .step-item .text-section .idx {
    color: #fff;
    background-color: #FF6643;
    padding: 8px;
    border-radius: 50%;
    margin-bottom: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;
}

.main .bottom-section .step-item .text-section .title {
    font-size: 28px;
    font-weight: 700;
    margin: 0px 0px 10px 0px;
    color: var(--merquryDarkBlue);
    text-align: right;
    text-wrap: balance;
}


.main .bottom-section .step-item .text-section .desc {
    font-size: 16px;
    font-weight: 400;
    margin: 0px 0px 0px 0px;
    color: #000;
    text-align: right;
}

.main .bottom-section .step-item:nth-child(2n+0) .text-section .title {
    text-align: left;
}

.main .bottom-section .step-item:nth-child(2n+0) .text-section .desc {
    text-align: left;
}

.main .bottom-section .step-item .image-section  {
    display: flex;
    flex:1;
}

.main .bottom-section .step-item .image-section img  {
    max-width: 130px;
    width: 100%;
    height: auto;
    margin: 0px auto;
}

@media only screen and (max-width: 600px) {

    .main .bottom-section .step-item:nth-child(2n+0) {
        flex-direction: row;
    }

    .main .bottom-section .step-item .text-section {
        align-items: flex-start;
        flex-basis: 70%;
        margin-right: 20px;
    }

    .main .bottom-section .step-item .text-section .idx {
        width: 30px;
        height: 30px;
        font-size: 18px;
    }

    .main .bottom-section .step-item .text-section .title {
        text-align: left;
        font-size: 25px;
    }
    
    .main .bottom-section .step-item .text-section .desc {
        text-align: left;
        font-size: 13px;
    }

    .main .bottom-section .step-item .image-section img  {
        max-width: 95px;
        margin: 0px 0px 0px auto;
    }

}