.main-content-wrapper {
    /*background: url('../../assets/images/bg-1.png');
    background-repeat: no-repeat;
    background-size: 100%;*/
    flex: 1;
    overflow-x: hidden;
    min-height: calc(100% - 78px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 950px) {
    .main-content-wrapper {
        min-height: calc(100% - 67px);
    }
}

.main {
    max-width: 1300px !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 40px 20px 50px 20px;
}

.tag {
    color: #000;
    text-align: center;
    font-family: 'DM Sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 99.327%;
    margin-bottom: 10px;
    position: relative;
}

.tag .circle-1 {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 130px;
    left: 80px;
    background-color: var(--pinkDark);
    border-radius: 50%;
}

.tag .circle-2 {
    width: 128px;
    height: 128px;
    position: absolute;
    top: 80px;
    right: 0%;
    background-color: #FFDC60;
    border-radius: 50%;
}

.main h1 {
    color: #000;
    text-align: center;
    font-family: 'DM Sans';
    font-size: 80px;
    font-weight: 600;
    line-height: 110%;
    margin: 0px 0px 60px 0px;
    z-index: 100;
}

.main h1 .highlight {
    color: var(--merquryDarkPurple);
    z-index: 100;
}

.card-list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
}

.card {
    padding: 30px 30px;
    border-radius: 32px;
    background: var(--merquryDarkBlue);
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    flex-basis: 33%;
}

.card .title {
    color: #fff;
    font-family: 'DM Sans';
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 24px;
}

.card .fee {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 110%;
    text-wrap: nowrap;
    margin-bottom: 10px;
}

.card .fee .lower {
    color: var(--lightGray);
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
    margin-left: 5px;
}

.card .feature-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px 0px 30px 0px;
}

.card .feature-list .feature-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0px;
}

.card .feature-list .feature-item .bullet {
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--merquryDarkBlue);
}

.card .feature-list .feature-item span {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    margin-left: 18px;
}

.card .app-links {
    margin-top: auto;
    padding: 15px auto;
    color: var(--merquryDarkBlue);
    background-color: #fff;
    align-self: center;
}

/*span.scroll-text {
    color: var(--lightGray);
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}*/

@media only screen and (max-width: 1040px) {

    .card .title {
        font-size: 18px;
    }

    .card .fee {
        font-size: 16px;
    }

    .card .fee .lower {
        font-size: 10px;
    }

    .card .feature-list .feature-item .bullet {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 12px;
    }

    .card .feature-list .feature-item span {
        font-size: 14px;
        margin-left: 10px;
    }
}

@media only screen and (max-width: 940px) {
    .card {
        padding: 30px 20px;
    }
}

@media only screen and (max-width: 1280px) {

    .card-list {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .card {
        margin: 0px 10px 10px 10px;
        flex-basis: 40%;
        max-width: 300px;
    }
}

@media only screen and (max-width: 900px) {

    .card-list {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

    }

    .card {
        margin: 0px 0px 10px 0px;
        width: 100%;
        max-width: 300px;
    }

    .card .feature-list {
        margin-top: 15px;
    }
}


@media only screen and (max-width: 660px) {
    /*.main-content-wrapper {
        background: url('../../assets/images/bg-2.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }*/

    .main {
        padding: 30px 20px 20px 20px;
    }

    .tag {
        display: none;
    }

    .main h1 {
        font-size: 40px;
        margin: 0px 0px 30px 0px;
    }

    .desc {
        font-size: 14px;
    }

    .card {
        padding: 24px;
    }

    .card .title {
        font-size: 18px;
        margin-bottom: 16px;
    }

}

.dip-note {
    color: var(--lightGray);
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
    margin: 25px 0px 0px 0px;
    width: 100%;
    text-align: cenleftter;
}