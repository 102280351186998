.main-content-wrapper {
    background-size: 100%;
    flex: 1;
    overflow-x: hidden;
}

.main {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 50px;
    padding: 0px 10px 30px 10px;
}

.main>div:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
}

.main>div:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
}

.main>div:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
}

.main>div:nth-child(4) {
    grid-area: 2 / 3 / 3 / 4;
}

.main>div:nth-child(5) {
    grid-area: 1 / 2 / 3 / 3;
}

.feature-card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.feature-card .icon-wrapper {
    display: flex;
    background: #FF6643;
    border-radius: 20px;
    color: #fff;
    font-size: 35px;
    padding: 12px;
    margin-bottom: 6px;
}

.feature-card .title {
    color: var(--merquryDarkBlue);
    font-size: 22px;
    font-weight: 600;
    text-align: right;
    margin: 0px 0px 8px 0px;
}

.feature-card .desc {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    text-align: right;
    margin: 0px 0px 0px 0px;
}

.feature-card.reverse {
    align-items: flex-start;
}

.feature-card.reverse .title {
    text-align: left;
}

.feature-card.reverse .desc {
    text-align: left;
}

.screenshot-wrapper {
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.screenshot-wrapper>img {
    width: 80%;
    height: auto;
}

@media only screen and (max-width: 950px) {
    .main {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr auto;
        grid-row-gap: 40px;
        grid-column-gap: 10px;
        padding: 0px 20px 30px 20px;
    }

    .main>div:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
        }

    .main>div:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;    }

    .main>div:nth-child(3) {
        grid-area:2 / 1 / 3 / 2;    }

    .main>div:nth-child(4) {
        grid-area: 2 / 2 / 3 / 3;    }

    .main>div:nth-child(5) {
        grid-area:  3 / 1 / 4 / 3;   }

    .feature-card {
        align-items: center;
    }

    .feature-card .icon-wrapper {
        font-size: 28px;
    }

    .feature-card .title {
        text-align: center;
        font-size: 18px;
        margin: 0px 0px 8px 0px;
    }

    .feature-card .desc {
        text-align: center;
        font-size: 14px;
    }

    .feature-card.reverse {
        align-items: center;
    }

    .feature-card.reverse .desc {
        text-align: center;
    }

    .screenshot-wrapper>img {
        width: 80%;
        max-width: 300px;
        height: auto;
    }
}

@media only screen and (max-width: 600px) {
    .main {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(5, auto);
        grid-row-gap: 40px;
    }

    .main>div:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
    }

    .main>div:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
    }

    .main>div:nth-child(3) {
        grid-area: 4 / 1 / 5 / 2;
    }

    .main>div:nth-child(4) {
        grid-area: 5 / 1 / 6 / 2;
    }

    .main>div:nth-child(5) {
        grid-area: 3 / 1 / 4 / 2;
    }

    .feature-card {
        align-items: flex-start;
    }

    .feature-card .title {
        text-align: left;
        margin: 0px 0px 8px 0px;
    }

    .feature-card .desc {
        text-align: left;
    }

    .feature-card.reverse {
        align-items: flex-start;
    }

    .feature-card.reverse .title {
        text-align: left;
    }
    
    .feature-card.reverse .desc {
        text-align: left;
    }

    .screenshot-wrapper>img {
        width: 80%;
        max-width: 300px;
        height: auto;
    }
}