.main-content-wrapper {
    background: url('../../assets/images/bg-3.png');
    background-color: #28282f;
    background-repeat: no-repeat;
    background-size: 100%;
    flex: 1;
    overflow-x: hidden;
}

.main {
    width: 100%;
    height: 100%;
    padding: 80px 20px 60px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 50px;
}

.main.one-row {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
}

@media only screen and (max-width: 1100px) {
    .main {
        padding: 50px 20px 50px 20px;
        display: grid;
        grid-column-gap: 2%;
        grid-row-gap: 2%;
    }
}

.main>div:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
}

.main>div:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
}

.main>div:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
}

.main>div:nth-child(4) {
    grid-area: 2 / 1 / 3 / 2;
}

.main>div:nth-child(5) {
    grid-area: 2 / 2 / 3 / 3;
}

.main>div:nth-child(6) {
    grid-area: 2 / 3 / 3 / 4;
}

.main>div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.main div.left {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.main div.left .icon-wrapper {
    padding: 12px;
    font-size: 24px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    line-height: 80%;
}

.main span {
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
    text-transform: capitalize;
}

.main p {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    opacity: 0.5;
}

@media only screen and (max-width: 990px) {
    .main-content-wrapper {
        background: url('../../assets/images/bg-3.png');
        background-color: #28282f;
        background-repeat: no-repeat;
        background-size: 300%;
        flex: 1;
        overflow-x: hidden;
    }

    .main {
        padding: 60px 20px 50px 20px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    .main.one-row {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    }

    .main>div:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
    }
    
    .main>div:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
    }
    
    .main>div:nth-child(3) {
        grid-area: 2 / 1 / 3 / 2;
    }
    
    .main>div:nth-child(4) {
        grid-area: 2 / 2 / 3 / 3;
    }
    
    .main>div:nth-child(5) {
        grid-area: 3 / 1 / 4 / 2;
    }
    
    .main>div:nth-child(6) {
        grid-area: 3 / 2 / 4 / 3;
    }

    .main.one-row>div:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
    }

    .main.one-row>div:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
    }

    .main.one-row>div:nth-child(3) {
        grid-area: 2 / 1 / 2 / 3;
    }

    .main>div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .main>div>.left {
        margin: 0px 0px 10px 0px;
    }

    .main>div>.right {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main span {
        font-size: 20px;
        text-align: center;
    }

    .main p {
        font-size: 14px;
        text-align: center;
    }
}