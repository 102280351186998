.main-content-wrapper {
    overflow-x: hidden;
    padding: 0px 0px;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0px 70px;
    margin: 0px auto 20px auto;
}

.section-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 0px;
}

.section-top .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.section-top .left>img {
    width: 123px;
    height: auto;
    margin-bottom: 16px;
}

.section-top .left>span.desc {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    max-width: 500px;
    margin-bottom: 24px;
    opacity: 0.7;
}

.info-list {
    display: flex;
    flex-direction: column;
    align-self: start;
    justify-content: flex-start;
    margin-bottom: 25px;
}

.info-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
}

.info-item .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--merquryDarkBlue);
    border-radius: 50%;
    padding: 8px;
    color: #fff;
    font-size: 13px;
    margin-right: 17px;
}

.info-item span {
    color: #69696E;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400!important;
    margin-bottom: 0px!important;
}

span.contact-us {
    color: var(--merquryDarkBlue);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0px;
}

span.contact-us svg {
    margin-left: 10px;
}

span.contact-us:hover {
    opacity: 0.7;
}

.section-top .right {
    flex: 0 0 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 45px;
    margin-left: 80px;
}

.section-top .right span {
    color: var(--merquryDarkBlue);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.4px;
    margin-bottom: 30px;
    cursor: pointer;
}

.section-top .right span:hover {
    opacity: 0.7;
}

.section-bottom {
    color: #999FAE;
    font-family: 'DM Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 99.327%;
    letter-spacing: -0.35px;
    padding: 30px 0px 20px 0px;
    border-top: 1px solid #E3E6E9;
}

@media only screen and (max-width: 750px) {
    .main {
        padding: 0px 30px;
        margin: 10px auto;
    }
    .section-top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px 0px 0px 0px;
    }
    .section-top .right {
        flex: 1;
        width: 100%;
        padding-top: 0px;
        margin-left: 0px;
        border-top: 1px solid #5956E9;
        padding: 25px 0px 0px 0px;
    }
}