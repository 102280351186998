.main {
    border-radius: 100px;
    background: var(--merquryDarkBlue);
    padding: 14px 60px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
    cursor: pointer;
}

.main.reverse {
    background: #fff;
    color: var(--merquryDarkBlue);
}

.main:hover {
    background: var(--merquryDarkPurple);
}

.main.reverse:hover {
    color: #fff;
}