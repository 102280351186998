.main {
    width: 100%;
    overflow-y: scroll;
}

.flyer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 900px;
    margin: 0px auto;
}

.flyer-container img {
    width: 100%;
    height: auto;
    margin: 0px 0px 20px 0px;
    border: 1px solid #000;
}