.main-content-wrapper {
    background-size: 100%;
    flex: 1;
    overflow-x: hidden;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 20px 50px 20px;
}

.header-title {
    color: var(--merquryDarkBlue);
    font-size: 80px;
    font-weight: 500;
    margin-bottom: 30px;
}

.header-title>.highlight {
    color: #5956E9;
}

.header-desc {
    color: #999FAE;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
    max-width: 500px;
    line-height: 150%;
}

.price-card-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.price-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 350px;
    margin: 0px 20px;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 40px 40px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.price-card .tag {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
}

.price-card .title {
    color: #000;
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 12px;
}

.price-card .price-text {
    color: #999FAE;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
}

.price-card .price-text>.highlight {
    color: #5956E9;
    font-size: 20px;
    font-weight: 600;
}

.price-card .feature-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 12px 0px;
}

.price-card .feature-row >span {
    color: #999FAE;
    font-size: 16px;
    font-weight: 400;
    margin-left: 8px;
}

.price-card .button {
    margin-top: 20px;
}

.one-off-fee {
    font-size:  12px;
    font-style: italic;
    margin-top: 20px
}

@media only screen and (max-width: 950px) {

    .header-title {
        font-size: 44px;
        margin-bottom: 20px;
    }

    .header-desc {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .price-card-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .price-card {
        margin: 0px 0px 20px 0px;
    }    
    
}