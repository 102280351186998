:root {
    --merquryDarkBlue: #1E2559;
    --merquryDarkPurple: #6935D3;
    --orangeLight: #FFEBE4;
    --orangeDark: #F7931A;
    --lightGray: #9395A4;
    --blueLight: #EBECFF;
    --blueDark: #9F9DF3;
    --greenLight: #DEF5E9;
    --greenDark: #5FC88F;
    --error: #f73636;
    --disabledButton: #DED5F7;
    --lightHeader: #cdbef5;
    --pinkLight: #FFEBF9;
    --pinkDark: #FE92E0;
}

* {
    box-sizing: border-box;
    font-family: 'Montserrat';
}

html,
body,
#root {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    overflow: hidden;
}

.main-content {
    max-width: 1040px;
    margin: 0px auto
}

.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #1E2559E5;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    padding: 10px 0px;
}

.modal-content {
    border-radius: 10px;
    background: #fff;
    padding: 30px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    max-width: 550px;
    height: fit-content;
    margin: auto 0px;
}

.modal-content h2 {
    color: #000;
    text-align: center;
    font-family: 'DM Sans';
    font-size: 40px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -2.88px;
    margin: 0px 0px 34px 0px;
}

.modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    border: 0px solid #B6B6B6;
    border-radius: 50%;
    padding: 12px;
    color: #B6B6B6;
    font-size: 18px;
    cursor: pointer;
    line-height: 70%;
    font-weight: 500;
}

@media only screen and (max-width: 550px) {

    .modal-content {
        padding: 20px 20px;
        border-radius: 20px;
    }

    .modal-content h2 {
        font-size: 30px;
        margin: 0px 0px 25px 0px;
    }

    .modal-close {
        top: 10px;
        right: 10px;
        padding: 8px;
        font-size: 14px;
        line-height: 65%;
    }
}

.modal-close:hover {
    position: absolute;
    border: 1px solid #000;
    color: #000;
}

.error-card {
    width: 100%;
    padding: 20px 10px;
    color: #fff;
    background-color: var(--error);
    border-radius: 10px;
    text-align: center;
}

.success-card {
    width: 100%;
    padding: 20px 20px;
    color: #fff;
    background-color: var(--greenDark);
    border-radius: 10px;
    text-align: center;
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: var(--orangeDark);
    border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

a {
    color: inherit;
    outline: none;
    text-decoration: none;
}