.input-wrapper {
    border-radius: 16px;
    border: 1px solid #E3E6E9;
    background: #fff;
    padding: 16px 23px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 15px;
}

.input-wrapper svg {
    font-size: 16px;
    color: var(--merquryDarkBlue);
    margin-right: 14px;
    margin-top: 5px;
}

.input-wrapper input,
.input-wrapper textarea {
    color: var(--merquryDarkBlue);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
    border: 0;
    outline: none;
    flex: 1;
    resize: none;
}

.input-wrapper input::placeholder,
.input-wrapper textarea::placeholder {
    color: #999FAE;
}

.input-wrapper:hover {
    border: 1px solid var(--merquryDarkBlue);
    background: #fff;
    box-shadow: 0px 20px 20px 0px rgba(30, 37, 89, 0.10);
}

.button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top:20px;
}

.button-row>div {
    padding: 22px 0px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.64px;
    border-radius: 20px;
    background: #1E2559;
    flex: 1;
    margin: 0px 10px;
    cursor: pointer;
}

.button-row>div:nth-child(2) {
    color: #000;
    background: #F3F3F3;
}

.button-row>div:hover {
    opacity: 0.7;
}

@media only screen and (max-width: 550px) {

    .input-wrapper {
        padding: 12px 18px;
    }
    
    .input-wrapper svg {
        font-size: 14px;
        margin-right: 10px;
    }
    
    .input-wrapper input,
    .input-wrapper textarea {
        font-size: 14px;
    }
    
    .button-row {
        margin-top:10px;
    }
    
    .button-row>div {
        padding: 22px 0px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.64px;
        border-radius: 20px;
        background: #1E2559;
        flex: 1;
        margin: 0px 10px;
        cursor: pointer;
    }
    
    .button-row>div:nth-child(2) {
        color: #000;
        background: #F3F3F3;
    }
    
    .button-row>div:hover {
        opacity: 0.7;
    }

}
