.main-content-wrapper {
    overflow-x: hidden;
    margin: 0px 20px 30px 20px;
}

.main-content-wrapper.school {
    margin: 0px 0px 30px 0px;
    padding: 40px 0px 50px 0px;
    background-color: #ECF2F6;
}

.main {
    position: relative;
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main iframe {
    aspect-ratio: 16 / 9;
    border-radius: 10px;
    width: 100%;
}

.h2 {
    color: var(--merquryDarkBlue);
    margin: 0px 0px 30px 0px;
    font-size: 70px;
    font-weight: 500;
}

.h3 {
    color: var(--merquryDarkBlue);
    margin: 30px 0px 30px 0px;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .main-content-wrapper {
        margin: 0px 20px 10px 20px;
    }
}

@media only screen and (max-width: 950px) {
    .h2 {
        font-size: 35px;
    }

    .h3 {
        font-size: 30px;
    }
}