.main-content-wrapper {
    overflow-x: hidden;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    padding: 50px 20px 20px 20px;
}

.main .top-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #E3E6E9;
}

.main .top-section .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 500px;
    position: relative;
    z-index: 15;
}

.main .top-section .left .tag {
    color: #C75C6F;
    font-family: 'DM Sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 99.327%;
    letter-spacing: -0.5px;
}

.main .top-section .left h2 {
    color: var(--merquryDarkBlue);
    font-family: 'DM Sans';
    font-size: 80px;
    font-weight: 500;
    line-height: 100%;
    text-transform: capitalize;
    margin: 0px 0px 32px 0px;
    text-align: left;
}

.main .top-section .left .desc {
    color: var(--lightGray);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.main .top-section .left span.video {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.64px;
    cursor: pointer;
    margin-top: 30px;
    padding: 10px 30px;
    background-color: var(--merquryDarkBlue);
    border-radius: 30px;
}

.main .top-section .left span.video:hover {
    opacity: 0.7;
}

.main .top-section .right {
    flex: 1;
    margin-left: 20%;
    z-index: 20;
}

.main .top-section .right img {
    width: 215px;
    height: auto;
}

@media only screen and (max-width: 950px) {
    .main .top-section .right {
        margin-left: 60px;
    }
}

@media only screen and (max-width: 780px) {

    .main {
        padding: 30px 20px 20px 20px;
    }
    

    .main .top-section .left h2 {
        font-size: 57px;
    }

    .main .top-section .left .desc {
        font-size: 16px;
    }

}

@media only screen and (max-width: 635px) {
    .main-content-wrapper {
        background-size: 100%;
    }

    .main .top-section .right {
        margin-left: 20px;
    }

    .main .top-section .left .tag {
        font-size: 16px;
        font-weight: 700;
        line-height: 99.327%;
        letter-spacing: -0.5px;
    }

    .main .top-section .left h2 {
        font-size: 54px;
        margin: 16px 0px;
    }

    .main .top-section .left .desc {
        font-size: 14px;
    }

    .main .top-section .right img {
        width: 135px;
    }
}

@media only screen and (max-width: 510px) {

    .main .top-section .right {
        display: none;
    }
}

.main .bottom-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 60px;
    grid-row-gap: 30px;
}

.main .bottom-section:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
}

.main .bottom-section:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
}

.main .bottom-section:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
}

.main .bottom-section:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
}

.main .bottom-section .grid-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.main .bottom-section .grid-item .left {
    margin-right: 0px;
    flex: 0 0 30%;
}

.main .bottom-section .grid-item .left img {
    width: 75%;
    height: auto;
    border-radius: 15px;
    margin: 0px auto;
}

.main .bottom-section .grid-item .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 0px;
    height: 100%;
}

.main .bottom-section .grid-item .right span:nth-child(1) {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -1.08px;
    margin: 10px 0px 10px 0px;
}

.main .bottom-section .grid-item .right span:nth-child(2) {
    color: var(--lightGray);
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.4px;
}

.main .bottom-section .grid-item .right span:nth-child(3) {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.64px;
    cursor: pointer;
}

.main .bottom-section .grid-item .right span:nth-child(3):hover {
    opacity: 0.7;
}


@media only screen and (max-width: 950px) {
    .main .bottom-section {
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }

    .main .bottom-section .grid-item .right span:nth-child(1) {
        font-size: 18px;
    }

    .main .bottom-section .grid-item .right span:nth-child(2) {
        font-size: 16px;
    }

    .main .bottom-section .grid-item .right span:nth-child(3) {
        font-size: 14px;
    }

    .main .bottom-section .grid-item .left img {
        width: 80%;
    }
}

@media only screen and (max-width: 700px) {
    .main .bottom-section {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }

    .main .bottom-section .grid-item .left img {
        width: 240px;
    }

    .main .bottom-section .grid-item .right {
        max-width: 400px;
    }

    .main .bottom-section .grid-item .right span:nth-child(1) {
        font-size: 20px;
        margin-bottom: 12px;
    }

    .main .bottom-section .grid-item .right span:nth-child(2) {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .main .bottom-section .grid-item .right span:nth-child(3) {
        font-size: 14px;
    }
}