.main-content-wrapper {
    overflow-x: hidden;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    padding: 50px 20px 20px 20px;
}

.main .top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main .top-section h2 {
    font-size: 32px;
    font-weight: 600;
    color: #000;
    margin: 0px 0px 10px 0px;
}

.main .top-section span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin: 0px 0px 10px 0px;
}

.main .bottom-section {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
}

.main .bottom-section .quote-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 250px;
}

.main .bottom-section .quote-wrapper .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--merquryDarkBlue);
    color: #fff;
    font-size: 40px;
    margin: 0px 0px 20px 0px;
}

.main .bottom-section .quote-wrapper span.quote {
    color: #000;
    font-size: 20px;
    margin: 0px 0px 20px 0px;
    text-align: center;
}

.main .bottom-section .quote-wrapper span.name {
    color: var(--merquryDarkBlue);
    font-size: 32px;
    font-weight: 700;
    margin: 0px 0px 0px 0px
}

.main .bottom-section .quote-wrapper span.job {
    color: #000;
    font-size: 16px;
}

.main .bottom-section .quote-carousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
}

.main .bottom-section .quote-carousel .idx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--merquryDarkBlue);
    color: #fff;
    font-size: 12px;
    margin: 0px 5px;
    cursor: pointer;
}

.main .bottom-section .quote-carousel .idx.active {
    background-color: #FF6643;
}

@media only screen and (max-width: 600px) {
    .main .bottom-section .quote-wrapper .avatar {
        width: 40px;
        height: 40px;
        font-size: 22px;
    }
    
    .main .bottom-section .quote-wrapper span.quote {
       font-size: 16px;
    }
    
    .main .bottom-section .quote-wrapper span.name {
        font-size: 24px;
    }
    
    .main .bottom-section .quote-wrapper span.job {
        font-size: 14px;
    }
}