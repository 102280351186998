.main-content-wrapper {
    background: #fff;
    flex: 1;
    overflow-x: hidden;
    min-height: calc(100% - 78px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

@media only screen and (max-width: 950px) {
    .main-content-wrapper {
        min-height: auto;
    }
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 40px 20px;
}


/**
BUSINESS SECTION
**/

.main.business {
    position: relative;
}

.business .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 250px 100px 50px;
    background-color: var(--disabledButton);
    border-radius: 40px;
    flex-basis: 80%;
    position: relative;
}

.business .left.school {
    padding: 70px 250px 70px 50px;
    background-color: var(--merquryDarkBlue);
}

.business .left.oms {
    background-color: #FF6643;
}

.business .left .title {
    font-size: 80px;
    font-weight: 600;
    color: var(--merquryDarkBlue);
    line-height: 100%;
    margin: 0px 0px 20px 0px;
}

.business .left.school .title {
    color: #fff;
    font-size: 70px;
}

.business .left.oms .title {
    color: #fff;
    font-size: 70px;
}

.business .left .desc {
    font-size: 19px;
    font-weight: 400;
    color: #000;
    line-height: 140%;
    margin: 0px 30px 20px 5px;
}

.business .left.school .desc {
    color: #fff;
    font-size: 17px;
}

.business .left.oms .desc {
    color: #fff;
    font-size: 17px;
}

.business .left .store-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
}

.business .left .store-icons img {
    max-width: 230px;
    height: auto;
    width: 100%;
    cursor: pointer;
}

.business .left .store-icons img:first-child {
    margin-right: 9px;
}

.business .right {
    flex: 1;
    z-index: 50;
}

.business .right img {
    width: 400px;
    height: auto;
    position: absolute;
    top: calc(50% - 200px);
    right: 20px;
}

.isba-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    width: fit-content;
    bottom: 60px;
    right: 20px;
}

img.isba {
    width: auto;
    max-width: 150px;
    height: auto;
    max-height: 120px;
}

.ncass-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    width: fit-content;
    bottom: 60px;
    right: 20px;
}

img.ncass {
    width: auto;
    max-width: 300px;
    height: auto;
    max-height: 120px;
}

@media only screen and (max-width: 950px) {

    .main-content-wrapper {
        justify-content: flex-start;
    }

    .main.business {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .business .left {
        padding: 30px 30px 100px 30px;
        border-radius: 20px;
        flex-basis: 100%;
        max-width: 400px;
    }

    .business .left.school {
        padding: 30px 30px 100px 30px;
    }

    .business .left .title {
        font-size: 32px;
        margin: 0px 0px 10px 0px;
    }

    .business .left.school .title {
        font-size: 32px;
    }

    .business .left.oms .title {
        font-size: 32px;
    }

    .business .left .desc {
        font-size: 12px;
        margin: 0px 0px 10px 3px;
    }

    .business .left.school .desc {
        font-size: 12px;
    }

    .business .left.oms .desc {
        font-size: 12px;
    }

    .business .left .store-icons img {
        max-width: 115px;
    }

    .business .left .store-icons img:first-child {
        margin-right: 5px;
    }

    .business .right {
        flex-basis: 100%;
    }

    .business .right img {
        width: 300px;
        max-width: 100%;
        position: static;
        margin: -80px auto 0px auto;
    }
}

@media only screen and (max-width: 950px) {
    .isba-div {
        position: static;
    }

    img.isba {
        width: auto;
        max-width: 150px;
        height: auto;
        max-height: 120px;
    }

    .ncass-div {
        position: static;
    }

    img.ncass {
        width: 100%;
        max-width: 300px;
        height: auto;
        max-height: 120px;
    }
}

.quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 47px;
    background: var(--merquryDarkBlue);
    width: 98%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.quote .text {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    z-index: 100;
    font-style: italic;
    margin-bottom: 10px;
}

.quote .author {
    color: var(--lightGray);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    z-index: 100;
    text-align: right;
}

@media only screen and (max-width: 950px) {
    .quote {
        padding: 20px 20px;
        margin-bottom: 20px;
    }

    .quote .text {
        font-size: 18px;
    }

    .quote .author {
        font-size: 14px;
    }
}

/**
CONSUMER SECTION
**/


.main.personal {
    position: relative;
    padding: 20px 20px 20px 20px;
}

.personal .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-basis: 60%;
    height: 100%;
    position: relative;
}

.personal .left .title {
    color: #000;
    font-size: 50px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -2.6px;
    font-family: 'DM Sans';
    margin: 0px;
    z-index: 10;
}

.personal .left .title .highlight {
    color: var(--merquryDarkPurple)
}

.personal .left .desc {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin: 40px 0px 50px 0px;
    width: 90%;
    z-index: 100;
}

.personal .left .store-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.personal .left .store-icons img {
    max-width: 230px;
    height: auto;
    width: 100%;
    cursor: pointer;
}

.personal .left .store-icons img:first-child {
    margin-right: 9px;
}

.personal .right {
    flex: 1;
    z-index: 5;
    display: flex;
}

.personal .right img {
    flex: 1;
    width: 100%;
    height: auto;
    max-width: 305px;
    max-height: 600px;
    object-fit: contain;
    margin: 0px auto;
}

@media only screen and (max-width: 760px) {

    .main.personal {
        flex-direction: column;
    }

    .personal .left .title {
        font-size: 32px;
    }

    .personal .left .desc {
        font-size: 12px;
        margin: 20px 0px 20px 0px;
        width: 100%;
    }

    .personal .left .store-icons img {
        max-width: 115px;
    }

    .personal .left .store-icons img:first-child {
        margin-right: 5px;
    }

    .personal .right img {
        width: 100%;
        max-width: 200px;
    }
}

/**
 SCROLL TEXT
**/

.scroll-container {
    width: 100%;
    height: 2.5rem;
    font-family: Montserrat;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    color: #fff;
    position: absolute;
    bottom: 20px;
    left: 0px;
    overflow: hidden;
}

.scroll {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: fit-content;
    white-space: nowrap;
    animation: loop 30s linear infinite;
    position: absolute;
}

.scroll-item {
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    color: var(--merquryDarkBlue);
    font-size: .9rem;
    background-color: #fff;
    padding: 0.7rem 1rem;
    margin: 0rem 1rem;
    border-radius: 0.4rem;
    white-space: nowrap;
    box-shadow:
        0 0.1rem 0.2rem #00000033,
        0 0.1rem 0.5rem #0000004d,
        0 0.2rem 01.5rem #00000066,
}

@keyframes loop {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
    }
}

@media only screen and (max-width: 950px) {

    .scroll-container {
        display: none;
    }

}