.main-content-wrapper {
    overflow-x: hidden;
    margin: 0px 20px 30px 20px;
}

.main {
    position: relative;
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h2 {
    color: var(--merquryDarkBlue);
    margin: 0px 0px 10px 0px;
    font-size: 32px;
    font-weight: 600;
}

.h3 {
    color: var(--merquryDarkBlue);
    margin: 0px 0px 30px 0px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .main-content-wrapper {
        margin: 0px 20px 10px 20px;
    }
}

